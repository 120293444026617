import {lazy} from "react"

export const Routes = [
    {
        path: "/centers/list",
        component: lazy(() => import("../views/centers/centers-list")),
        meta: {
            action: "call",
            resource: "STOCK_ADMIN_PERMISSION"
        }
    },
    {
        path: "/centers/:centerid",
        component: lazy(() => import("../views/centers/center-view")),
        meta: {
            action: "call",
            resource: "STOCK_ADMIN_PERMISSION"
        }
    },
    {
        path: "/categories/list",
        component: lazy(() => import("../views/categories/categories-list")),
        meta: {
            action: "call",
            resource: "STOCK_ADMIN_PERMISSION"
        }
    },
    {
        path: "/unitOfMeasurements/list",
        component: lazy(() => import("../views/unitOfMeasurements/unitOfMeasurements-list")),
        meta: {
            action: "call",
            resource: "STOCK_ADMIN_PERMISSION"
        }
    },
    {
        path: "/cashTransactions/list",
        component: lazy(() => import("../views/cash/cash-transaction-list")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    {
        path: "/shipmentTransactions/list",
        component: lazy(() => import("../views/shipmentTransactions/shipmentTransactions-list")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    // {
    //     path: "/items/list",
    //     component: lazy(() => import("../views/items/items-list")),
    //     meta: {
    //         action: "call",
    //         resource: "STOCK_ALL_PERMISSION"
    //     }
    // },
    {
        path: "/items/:id",
        component: lazy(() => import("../views/items/item-transactions-list")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    {
        path: "/center/items/:centerid/:id",
        component: lazy(() => import("../views/items/item-transactions-list")),
        meta: {
            action: "call",
            resource: "STOCK_ADMIN_PERMISSION"
        }
    },
    // {
    //     path: "/notapprovedtransactions/list",
    //     component: lazy(() => import("../views/Transaction")),
    //     meta: {
    //         action: "call",
    //         resource: "STOCK_ALL_PERMISSION"
    //     }
    // },
    {
        path: "/my-wallet",
        component: lazy(() => import("../views/cash")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    {
        path: "/my-stock",
        component: lazy(() => import("../views/items")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    {
        path: "/cashtransactions/list",
        component: lazy(() => import("../views/cash/cash-transaction-list")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    // {
    //     path: "/currencies/list",
    //     component: lazy(() => import("../views/currency/currencies-list")),
    //     meta: {
    //         action: "call",
    //         resource: "STOCK_ADMIN_PERMISSION"
    //     }
    // },
    {
        path: "/Mycurrencies/list",
        component: lazy(() => import("../views/cash/currencies-list")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    {
        path: "/currency/:id",
        component: lazy(() => import("../views/cash/cash-transaction-list")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    {
        path: "/wallet/:id",
        component: lazy(() => import("../views/cashwallet")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    {
        path: "/center/currency/:centerid/:id",
        component: lazy(() => import("../views/cash/cash-transaction-list")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    {
        path: "/center/walletcurrency/:centerid/:id",
        component: lazy(() => import("../views/cashwallet/forwardshowactions")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    {
        path: "/notapprovedcashtransactions/list",
        component: lazy(() => import("../views/cash/NotApprovedCash-list")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    {
        path: "/alltransactionincenter/list",
        component: lazy(() => import("../views/cash/alltransaction-in-center-list")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    {
        path: "/allitemsincenter/list",
        component: lazy(() => import("../views/items/allitems-in-center-list")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    },
    {
        path: "/alltransaction/list",
        component: lazy(() => import("../views/cash/alltransaction")),
        meta: {
            action: "call",
            resource: "STOCK_ADMIN_PERMISSION"
        }
    },
    {
        path: "/allitems/list",
        component: lazy(() => import("../views/items/allitems")),
        meta: {
            action: "call",
            resource: "STOCK_ADMIN_PERMISSION"
        }
    },
    {
        path: "/my-center",
        component: lazy(() => import("../views/centers/my-center")),
        meta: {
            action: "call",
            resource: "STOCK_ALL_PERMISSION"
        }
    }
]
