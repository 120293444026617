// **  Initial State
const initialState = {
  loading: false,
  pendingcashCount: 0,
  pendingitemsCount: 0,
  pendingforadmincashCount: 0,
  pendingforadminitemsCount: 0

}

export const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STOCK_PENDING_COUNT':
      return {
        ...state,
        pendingcashCount: action.payload.pendingcashCount,
        pendingitemsCount: action.payload.pendingitemsCount,
        pendingforadmincashCount: action.payload.pendingforadmincashCount,
        pendingforadminitemsCount: action.payload.pendingforadminitemsCount
      }
    default:
      return state
  }
}
